
import { computed, defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModes } from '@/compositions/modes'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    PageContent,
    TmButton,
    DetailsHero,
    InfoCard,
    InfoCardRow,
  },
  setup() {
    const { isEmptyHalfMode, isEmptyMode } = useModes()
    const breadcrumbs = computed(() => [
      { label: 'Tickets', url: isEmptyHalfMode.value ? { name: 'base.tickets.main.gettingStarted' } : { name: 'base.tickets' } },
      { label: 'Settings' },
    ])
    const { openModal } = useModals()
    const computedArticle = computed(() => isEmptyMode.value ? 'is' : 'are')

    return {
      openModal,
      breadcrumbs,
      isEmptyMode,
      computedArticle,
    }
  },
})
